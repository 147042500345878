<template>
  <div class="modal report-modal">
    <div class="overlay" @click="cancel"></div>
    <div class="wrapper">
      <div class="container">
        <div class="overflow">
          <img class="close" @click="cancel" src="./../assets/close.svg" />
          <div class="desc">
            {{ $t(confirmationModal.text) }}
          </div>
          <div class="buttons">
            <button class="button" @click="cancel">{{ $t('CANCEL') }}</button>
            <button class="button red" @click="confirm">{{ $t('YES') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "ReportModal",
  props: [],
  data: function () {
    return {};
  },
  methods: {
    ...mapActions('app', ['closeNotification', 'closeConfirmationModal']),
    confirm() {
      this.confirmationModal.resolve();
      this.closeConfirmationModal();
    },
    cancel() {
      this.confirmationModal.reject();
      this.closeConfirmationModal();
    }
  },
  computed: {
    ...mapState('app', ['confirmationModal']),
    ...mapGetters('app', ['notificationText'])
  }
};
</script>
