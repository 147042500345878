<template>
  <main class="main inside-page form-page profile-page">
    <div class="section profile-section">
      <div class="wrapper">
        <div class="title">
          <div class="left">
            <img class="img" src="../assets/decor10.svg"/>
            <img class="img mob" src="../assets/decor14Mob.svg"/>
            <span>{{$t('MY PROFILE')}}</span>
          </div>
        </div>
        <div class="profile">
          <div @click="logOut" class="logout">
            <img class="img" src="../assets/logout.svg"/>
            <div class="desc">{{$t('LOG OUT')}}</div>
          </div>
          <div class="profile-info-container">
            <div class="profile-info">
              <div class="left">
                <div class="profile-name">
                  <div class="desc big">{{$t('name')}} : {{ profile.name }}</div>
                  <div class="img-container">
                    <img v-if="profile.is_channel" class="img" src="../assets/profile/shield.svg"/>
                    <div class="desc tooltip">{{$t('Channel Status expires on')}} {{ channelExpireAt | moment('DD/MM/YYYY') }}</div>
                  </div>
                </div>
                <div class="profile-email-phone">
                  <div class="desc">
                    {{$t('email')}} : {{ profile.email }}<br/>
                    {{$t('Phone Number')}}: {{ profile.phone }}
                  </div>
                  <div class="button-container">
                    <button class="button" @click="$parent.openPersonalInfoModal">{{$t('EDIT')}}</button>
                  </div>
                </div>
                <div class="profile-status">
                  <div class="left">
                    <div class="desc big">{{$t('Buy Channel Status')}}</div>
                    <div class="desc">{{$t('You will get :')}}</div>
                    <div class="list">
                      <div class="desc">{{$t('Created topics are highlighted in bold font')}}</div>
                      <div class="desc">
                        {{$t('Ability to change topics colour')}}
                      </div>
                      <div class="desc">
                        {{$t('Create and Join up to 5 topics at once')}}
                      </div>
                      <div class="desc">
                        {{$t('Send one file or photo per chat for free!')}}
                      </div>
                      <div class="desc">
                        {{$t('Your topic will always be at the top of the list')}}
                      </div>
                    </div>
                  </div>
                  <div class="right">
                    <div class="desc">{{$t('Price -')}} {{  formatPoints(settings('channel_status_cost')) }} {{$t('points per week')}}</div>
                    <button @click="buyChannelStatus" class="button">{{$t('Buy Channel status for 1 week')}}</button>
                  </div>
                </div>
                <div class="profile-balance">
                  <div class="info">
                    <div class="left">
                      <div class="desc big">{{$t('Balance')}}</div>
                      <div class="desc">{{ formatPoints(profile.balance) }} POINTS</div>
                    </div>
                    <img class="img" src="../assets/profile/coin.svg"/>
                  </div>
                  <button class="button" @click="openTopUpModal">{{$t('Top-UP')}}</button>
                </div>
              </div>
              <div class="right">
                <div class="profile-bundle">
                  <div class="desc big">{{$t('Buy Bundle')}}</div>
                  <div class="list">
                    <div v-for="({id, price, points}, i) in bundles()" :key="id" class="item">
                      <img class="img" :src="getCoinImage(i)"/>
                      <div class="desc big">{{ formatPoints(points, false) }} = {{ price }} €</div>
                      <button @click="choiceBundle(id)" class="button">{{$t('BUY')}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="profile-history-container">
            <div class="profile-history">
              <div class="desc big">
                {{$t('PURCHASE HISTORY')}}
                <img class="img" src="../assets/profile/history.svg"/>
              </div>
              <div v-if="paymentHistory && !paymentHistory.length" class="desc no-istory">{{$t('No Purchase History')}}</div>
              <div class="profile-table" v-if="paymentHistory && paymentHistory.length">
                <table>
                  <thead>
                  <tr>
                    <td class="desc">
                      <b>{{$t('PURCHASE')}}</b>
                    </td>
                    <td class="desc">
                      <b>{{$t('PRICE')}}</b>
                    </td>
                    <td class="desc">
                      <b>{{$t('POINTS AMOUNT')}}</b>
                    </td>
                    <td class="desc">
                      <b>{{$t('DATE')}}</b>
                    </td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="({id, sum, created_at, points}) in paymentHistory" :key="id">
                    <td class="desc">{{$t('Balance top-up')}}</td>
                    <td class="desc">{{ sum }} €</td>
                    <td class="desc">{{ formatPoints(points) }}</td>
                    <td class="desc">{{ created_at | moment("D.MM.YYYY") }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "@/axios";

export default {
  name: "Profile",
  props: [],
  components: {},
  data: function () {
    return {
      paymentHistory: [],
      coinImages: [
        "../assets/profile/coins.svg",
        "../assets/profile/coins2.svg",
        "../assets/profile/coins3.svg"
      ],
      uploadedDocument: null,
      uploadedImage: null,
    };
  },
  mounted() {
    this.getDepositHistory()
    this.getBundles()
  },
  methods: {
    logOut() {
      const self = this;

      axios.post('logout').then(async ({data}) => {
        if (data.status === 'OK') {
          await self.logout({pushToLogin: true})
        }
      })
    },
    getCoinImage(index) {
      let images = require.context('../assets/profile', false, /\.svg$/)
      return images('./coins' + (index ? index + 1 : '') + ".svg");
    },
    choiceBundle(id) {
      this.SET_TOP_UP_BUNDLE_ID(id);
      this.$parent.openTopUpModal()
    },
    openTopUpModal() {
      this.SET_TOP_UP_BUNDLE_ID(null);
      this.$parent.openTopUpModal()
    },
    getDepositHistory () {
      const self = this;

      axios.get('deposit/history').then(({data}) => {
        if (data.status === 'OK') {
          self.paymentHistory = data.payload;
        }
      })
    },
    formatPoints(pts, big = true) {
      if (pts >= 1000000) {
        return (pts / 1000000).toFixed(1) + (big ? 'M' : 'm');
      } else if (pts >= 1000) {
        return (pts / 1000).toFixed(1) + (big ? 'K' : 'k');
      } else {
        return pts;
      }
    },
    async buyChannelStatus() {
      const self = this;

      this.showConfirmationModal(this.$t('Are you sure you want to buy channel status ?')).then(() => {
        axios.post('user/become-channel').then(({data}) => {
          if (data.status === 'OK') {
            self.SET_BALANCE(data.payload.balance);
            self.SET_IS_CHANNEL(true);
            self.SET_CHANNEL_EXPIRE_AT(data.payload.channel_status_due_date);
            const endDate = this.$moment(data.payload.channel_status_due_date).format('DD/MM/YYYY');
            self.showNotification({text: this.$t(`You successfully purchased channel status for 1 week, which ends on {endDate}`, {endDate})})
          } else if(data.status === 'ERROR') {
            self.showNotification({text: data.message})
          }
        }).catch(({response}) => {
          if (response.status === 400) {
            if (response.data.message) {
              self.showNotification({text: response.data.message})
            }
          }
        })
      })
    },
    ...mapActions('app', ['getBundles', 'showNotification', 'showConfirmationModal']),
    ...mapMutations('app', ['SET_TOP_UP_BUNDLE_ID']),
    ...mapActions('user', ['logout']),
    ...mapMutations('user', ['SET_BALANCE', 'SET_IS_CHANNEL', 'SET_CHANNEL_EXPIRE_AT'])
  },
  computed: {
    ...mapGetters('user', ['profile', 'channelExpireAt']),
    ...mapGetters('app', ['settings', 'bundles']),
    balance() {
      if (this.profile.balance >= 1000) {
        return (this.profile.balance / 1000).toFixed(1) + 'K ';
      } else {
        return this.profile.balance
      }
    }
  }
};
</script>
