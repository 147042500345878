import axios from '@/axios';

const state = {
    rooms: [],
}

const getters = {
    rooms: state => state.rooms,
}

const actions = {
    getRooms: async ({commit}) => {
        try {
            const {data} = await axios.get(process.env.VUE_APP_API + 'rooms/index');
            commit('SET_ROOMS', data.payload.map((room) => {
                return {
                    ...room,
                    slug: room.title.toLowerCase()
                        .replace(/[^\w\s-]/g, '')
                        .replace(/\s+/g, '-')
                        .replace(/--+/g, '-')
                        .trim()
                }
            }))
            // eslint-disable-next-line no-empty
        } catch (e) {
        }
    },
    // eslint-disable-next-line no-unused-vars
    generateSlug({commit}, room) {
        return room.title.toLowerCase()
            .replace(/[^\w\s-]/g, '')
            .replace(/\s+/g, '-')
            .replace(/--+/g, '-')
            .trim()
    }
}

const mutations = {
    SET_ROOMS: (state, rooms) => (state.rooms = rooms),
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}