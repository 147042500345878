<template>
  <main class="main inside-page form-page rooms-page">
    <div class="section rooms-section">
      <div class="wrapper">
        <div class="top">
          <div class="title">
            <img class="img" src="../assets/decor10.svg" />
            <img class="img mob" src="../assets/decor10Mob.svg" />
            <span>{{$t('ENTER ROOM')}}</span>
          </div>
          <div class="right">
            <a href="#" @click.prevent="startRandomTopic" class="button button-red">{{$t('START RANDOM CHAT')}}</a>
          </div>
        </div>
        <div class="room-list">
          <router-link v-for="(room) in rooms" :to="`rooms/${room.slug}`" :key="room.id" class="room-list__item">
            <div class="room-list__item-wrapper">
              <img class="img" :src="room.image_url"/>
              <div class="desc">{{ room.title }}</div>
            </div>
          </router-link>
          <router-link :to="{name: 'Topics', params: {name: 'all'}}" class="room-list__item">
            <div class="room-list__item-wrapper">
              <img class="img" src="@/assets/arrow.svg"/>
              <div class="desc">
                {{$t('VIEW ALL ROOMS')}}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "Rooms",
  props: [],
  components: {
  },
  data: function () {
    return {
    };
  },
  async mounted() {
    await this.getRooms();
  },
  methods: {
    ...mapActions('rooms', ['getRooms']),
    ...mapActions('topics', ['startRandomTopic']),
  },
  computed: {
    ...mapGetters('rooms', ['rooms'])
  }
};
</script>
