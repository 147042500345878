<template>
  <div class="modal personal-info-modal">
    <div class="overlay" @click="$parent.closePersonalInfoModal"></div>
    <div class="wrapper">
      <div class="container">
        <img class="close" @click="$parent.closePersonalInfoModal" src="./../assets/close.svg"/>
        <ValidationObserver ref="form" slim v-slot="{handleSubmit, valid, pristine}">
          <form @submit.prevent="submit" class="form-container">
            <div class="overflow">
              <div class="form">
                <div class="desc big">
                  <b>{{ $t('Edit personal information') }}</b>
                </div>
                <ValidationProvider rules="required" v-slot="{ errors }" vid="name"
                                    slim>
                  <label class="label">
                    <div class="desc">{{ $t('NAME') }}</div>
                    <input required v-model="name" name="name" type="text"/>
                    <div v-if="errors.length" class="error">
                      {{ errors[0] }}
                    </div>
                  </label>
                </ValidationProvider>
                <ValidationProvider rules="required|email" v-slot="{ errors }" vid="email"
                                    slim>
                  <label class="label">
                    <div class="desc">{{ $t('EMAIL') }}</div>
                    <input required v-model="email" name="email" type="text"/>
                    <div v-if="errors.length" class="error">
                      {{ errors[0] }}
                    </div>
                  </label>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }" vid="phone"
                                    slim>
                  <label class="label">
                    <div class="desc">{{ $t('PHONE NUMBER') }}</div>
                    <input required v-model="phone" name="phone" type="text"/>
                    <div v-if="errors.length" class="error">
                      {{ errors[0] }}
                    </div>
                  </label>
                </ValidationProvider>
              </div>
              <div class="buttons">
                <button class="button" @click="$parent.closePersonalInfoModal">{{ $t('Cancel') }}</button>
                <button :disabled="!valid && !pristine" type="submit" class="button red">{{ $t('SAVE') }}</button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>


<script>
import {mapGetters, mapMutations} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import axios from "@/axios";

export default {
  name: "PersonalInfoModal",
  props: [],
  components: {
    ValidationProvider,
    ValidationObserver
  },
  created() {
    this.phone = this.profile.phone || '';
    this.email = this.profile.email || '';
    this.name = (this.profile.name || '').trim();
  },
  data: function () {
    return {
      name: '',
      phone: '',
      email: ''
    };
  },
  methods: {
    submit() {
      const self = this;

      axios.put('user/profile', {
        name: this.name,
        email: this.email,
        phone: this.phone
      }).then(({data}) => {
        if (data.status === 'OK') {
          self.SET_PROFILE({
            ...self.profile,
            name: self.name,
            email: self.email,
            phone: self.phone
          });
          self.$parent.closePersonalInfoModal();
        } else if (data.errors) {
          this.$refs.form.setErrors(data.errors);
        }
      }).catch(async ({response}) => {
        if (response.data.errors) {
          this.$refs.form.setErrors(response.data.errors);
        }
      });
    },
    ...mapMutations('user', ['SET_PROFILE'])
  },
  computed: {
    ...mapGetters('user', ['profile'])
  }
};
</script>
