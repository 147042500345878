<template>
  <div class="modal report-modal">
    <div class="overlay" @click="closeNotification"></div>
    <div class="wrapper">
      <div class="container">
        <div class="overflow">
          <img class="close" @click="closeNotification" src="./../assets/close.svg" />
          <div class="desc">
            {{ notificationText }}
          </div>
          <div class="buttons">
            <button v-if="notificationOk"  class="button" @click="closeNotification">OK</button>
<!--            <button class="button red" @click="closeNotification">CLOSE</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ReportModal",
  props: [],
  data: function () {
    return {};
  },
  methods: {
    ...mapActions('app', ['closeNotification'])
  },
  computed: {
    ...mapGetters('app', ['notificationText', 'notificationOk'])
  }
};
</script>
