import Vue from "vue";
import VueRouter from "vue-router";
import PageNotFound from "./pages/PageNotFound.vue";
import Home from "./pages/Home.vue";
import Login from "./pages/Login.vue";
import Register from "./pages/Register.vue";
import Recover from "./pages/Recover.vue";
import PasswordRestore from "./pages/PasswordRestore.vue";
import Rooms from "./pages/Rooms.vue";
import Profile from "./pages/Profile.vue";
import Topics from "./pages/Topics.vue";
import CreateTopic from "./pages/CreateTopic.vue";
import Chat from "./pages/Chat.vue";
import PaymentSuccessPage from './pages/PaymentSuccessPage.vue'
import PaymentFaliPage from './pages/PaymentFaliPage.vue'
import TextPage from './pages/TextPage.vue'
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    {
      path: "/password-restore",
      name: "PasswordRestore.vue",
      component: PasswordRestore,
    },
    {
      path: "/recover",
      name: "Recover",
      component: Recover,
    },
    {
      path: "/rooms",
      name: "Rooms",
      component: Rooms,
      meta: { requiresAuth: true }
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
      meta: { requiresAuth: true }
    },
    {
      path: "/rooms/:name/chat",
      name: "Chat",
      component: Chat,
      meta: { requiresAuth: true }
    },
    {
      path: "/rooms/:name/create-topic",
      name: "CreateTopic",
      component: CreateTopic,
      meta: { requiresAuth: true }
    },
    {
      path: "/rooms/:name",
      name: "Topics",
      component: Topics,
      meta: { requiresAuth: true }
    },
    {
			path: '/success-payment',
			name: 'PaymentSuccessPage',
			component: PaymentSuccessPage
		},
		{
			path: '/fail-payment',
			name: 'PaymentFaliPage',
			component: PaymentFaliPage
		},
		{
      path: '/pages/:title',
      name: 'TextPage',
      component: TextPage,
      props: true
    },
    // {
    //   path: "/chat",
    //   name: "Chat",
    //   component: Chat,
    //   meta: { requiresAuth: true }
    // },
    {
      path: "*",
      name: "PageNotFound",
      component: PageNotFound,
      props: { title: "PageNotFound" },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the user is authenticated, for example, by checking if a token is present in localStorage

    if (store.getters['user/authenticated'] === false) {
      // If not authenticated, redirect to the login page
      next({ name: 'Login' });
    } else {
      // If authenticated, proceed to the requested route
      next();
    }
  } else {
    // If the route doesn't require authentication, proceed as usual
    next();
  }
});

export default router;