<template>
  <div class="modal chat-note-modal">
    <div class="overlay" @click="$parent.closeChatNoteModal();"></div>
    <div class="wrapper">
      <div class="container">
        <img class="close" @click="$parent.closeChatNoteModal();" src="./../assets/close.svg" />
        <div class="form-container">
          <div class="desc">
            <b>{{ $t('Please note!') }}</b>
            <br/><br/>
            {{ $t('All Topics are moderated and covered by the terms of service. In case of violations of our terms of use - access to chat rooms may be blocked.') }}
            <br/><br/>
            {{ $t('In our topics forbidden :') }}
            <br/><br/>
            <ol>
              <li class="desc">
                {{ $t('Send personal information, for example: Personal data, Location, Phone numbers, Email, Links to third party sites.') }}
              </li>
              <li class="desc">
                {{ $t('To call for violence, anti-Semitism, racism and other actions that may not be pleasant to a certain circle of people.') }}
              </li>
              <li class="desc">
                {{ $t('Send photos, files or use other methods of transmitting information that may be harmful to a certain group of people.') }}
              </li>
            </ol>
            <br/><br/>
            {{ $t('If you agree with our terms of service, please click Agree to proceed to the topic.') }}
          </div>
          <div class="buttons">
            <button class="button" @click="leave">{{ $t('LEAVE') }}</button>
            <button class="button red" @click="agree">{{ $t('AGREE') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {mapActions, mapMutations} from "vuex";

export default {
  name: "ChatNoteModal",
  props: ['chatNoteModalIsVisible'],
  data: function () {
    return {};
  },
  methods: {
    ...mapMutations('topics', ['ADD_CHAT_AGREED']),
    ...mapActions('topics', ['goToTopics']),
    agree() {
      this.ADD_CHAT_AGREED(this.$parent.currentTopicId);
      this.$parent.closeChatNoteModal();
    },
    leave() {
      this.$parent.closeChatNoteModal();
      this.goToTopics()
    }
  },
};
</script>
