<template>
  <label class="custom-checkbox">
    <div @click="toggleCheck" class="checkbox">
      <img v-if="isChecked" class="checkmark" src="@/assets/checkmark.svg" />
    </div>
    <slot></slot>
  </label>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      isChecked: this.value || false,
    };
  },
  methods: {
    toggleCheck() {
      this.isChecked = !this.isChecked;
      this.$emit('input', this.isChecked);
    },
  },
};
</script>

<style scoped>
.custom-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 11px;
}


.checkbox {
  background-image: url("../../src/assets/checkbox.svg");
  background-repeat: no-repeat;
  height: 29px;
  width: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.checkmark {
  position: relative;
  height: 25px;
}



</style>