<template>
  <main class="main inside-page form-page recover-page">
    <div class="section recover-section">
      <div class="wrapper">
        <img class="eyes" src="../assets/eyes2.svg"/>
        <div class="title">
          <img class="img" src="../assets/decor6Long.svg"/>
          <img class="cards" src="../assets/cards2.svg"/>
          <span>
            <img class="img mob" src="../assets/decor6LongMob.svg"/>
            <div>
              {{$t('FORGOT PASSWORD')}}
            </div>
          </span>
        </div>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }" slim class="form">
          <form @submit.prevent="handleSubmit(submit)" class="form">
            <ValidationProvider rules="required|email" v-slot="{ errors }" vid="email" slim>
              <label class="label">
                <div class="desc">{{$t('ENTER YOUR EMAIL')}}</div>
                <input required name="email" v-model="email" type="email"/>
                <div v-if="errors.length" class="error">
                  {{ errors[0] }}
                </div>
              </label>
            </ValidationProvider>
            <div class="link-container">
              <img class="decor7" src="../assets/decor7.svg"/>
            </div>
            <div class="button-container">
              <img class="decor8" src="../assets/decor8.svg"/>
              <button class="button">{{$t('SEND EMAIL')}}</button>
            </div>
            <div class="center-link-container">
              <router-link to="/register" class="desc">{{$t('DONT HAVE AN ACCOUNT? CREATE ONE!')}}</router-link>
            </div>
            <div class="center-link-container">
              <router-link to="/" class="desc">{{$t('Back to homepage')}}</router-link>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </main>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import axios from "@/axios";
import {mapActions} from "vuex";

export default {
  name: "Recover",
  props: [],
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: function () {
    return {
      email: ''
    };
  },
  mounted() {
  },
  methods: {
    submit() {
      const self = this;

      axios.post('user/request-password-reset', {
        email: this.email
      }).then(({data}) => {
        if (data.status === 'OK') {
          self.email = '';
          self.showNotification({text: this.$t("We've sent you an email with a link to reset your password. Please check your e-mail")})
        } else if (data.status === 'ERROR') {
          self.showNotification({text: data.message})
        }
      }).catch(({response}) => {
        if (response.data.status === 'ERROR') {
          self.showNotification({text: response.data.message})
        }
      })
    },
    ...mapActions('app', ['showNotification'])
  }
};
</script>
