import axios from "@/axios";
import router from "@/router";

const state = {
    topicId: null,
    currentRoom: {},
    topics: [],
    chatAgreed: localStorage.getItem('agreedChats') && JSON.parse(localStorage.getItem('agreedChats')) ? JSON.parse(localStorage.getItem('agreedChats')) : []
}

console.log(state);

const getters = {
    topicId: state => state.topicId,
    room: ({currentRoom}) => currentRoom,
    topics: ({topics}) => topics,
    chatAgreed: ({chatAgreed}) => chatAgreed,
    isChatAgreed: ({chatAgreed}) => (id) => chatAgreed.some(chatId => id === chatId),
}

const actions = {
    startRandomTopic({dispatch, commit}) {
        return axios.get('topics/random').then(async ({data}) => {
            if (data.status === 'OK') {
                commit('topics/SET_TOPIC', data.payload.topic.id, {root: true});
                const slug = await dispatch('rooms/generateSlug', data.payload.room, {root: true});
                console.log(slug, 'slug')
                await router.push({name: 'Chat', params: {name: slug}})
            }
        }).catch(({response}) => {
            if ((response.status === 404 || response.status === 400) && response.data.message) {
                dispatch('app/showNotification', {text: response.data.message}, {root: true})
            }
        })
    },
    joinChat({dispatch, commit}, {roomSlug, id}) {
        return axios.post('topics/join', {
            topic_id: id
        }).then(({data}) => {
            if (data.status === 'OK') {
                commit('topics/SET_TOPIC', id, {root: true})
                router.push({name: 'Chat', params: {name: roomSlug}})

                return true;
            }
        }).catch(({response}) => {
            const data = response.data;

            dispatch('getTopics', roomSlug);

            if (response.status === 404 || response.status === 400) {
                dispatch('app/showNotification', {text: data.message}, {root: true})
            }

            return false;
        })
    },
    async getTopics({rootGetters, commit}, roomSlug) {
        const rooms = rootGetters['rooms/rooms']
        let room = null;
        const all = roomSlug === 'all';

        if (!all) {
            room = rooms.find((room) => roomSlug === room.slug);
        }

        if (!all && !room) {
            await router.push('/rooms')
        } else {
            commit('SET_ROOM', all ? {title: 'all'} : room)
            let params = {};

            if (room) {
                params['room_id'] = room.id;
            }

            await axios.get('topics/index', {
                params
            }).then(({data}) => {
                if (data.status === 'OK') {
                    commit('SET_TOPICS', data.payload);
                }
            })
        }
    },
    async goToTopics() {
        return await router.push({name: 'Topics', params: {name: router.currentRoute.params.name}})
    }
}

const mutations = {
    SET_TOPIC: (state, topic) => (state.topicId = topic),
    SET_TOPICS: (state, topics) => (state.topics = topics),
    SET_ROOM: (state, room) => (state.currentRoom = room),
    ADD_CHAT_AGREED: (state, chatId) => {
        if (!state.chatAgreed.some(id => id === chatId)) {
            state.chatAgreed.push(chatId);
            localStorage.setItem('agreedChats', JSON.stringify(state.chatAgreed))
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}