<template>
  <div class="modal report-modal">
    <div class="overlay" @click="closeModal"></div>
    <div class="wrapper">
      <div class="container">
        <div class="overflow">
          <img class="close" @click="closeModal" src="./../assets/close.svg" />
          <div class="desc">
            {{ $t("Are you sure you want to send report ?") }}
          </div>
          <div class="buttons">
            <button class="button" @click="closeModal">{{ $t("CANCEL") }}</button>
            <button class="button red" @click="apply">{{ $t("APPLY") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "ReportModal",
  props: [],
  data: function () {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    apply() {
      this.$emit('apply');
    }
  },
};
</script>
