
import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import axios from '@/axios'
import store from './store';
import i18n from "@/i18n";
import './validation'
import './moment'
import './toastification'
Vue.prototype.$http = axios


global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import '../public/css/style.scss';
import 'promise-polyfill/src/polyfill';

Vue.prototype.$http.defaults.withCredentials = true;

new Vue({
	router,
	store,
	render: h => h(App),
	i18n
}).$mount('#app')
