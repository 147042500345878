<template>
  <main class="main inside-page form-page register-page">
    <div class="section register-section">
      <div class="wrapper">
        <img class="eyes" src="../assets/eyes2.svg"/>
        <div class="title">
          <img class="img" src="../assets/decor6.svg"/>
          <img class="cards" src="../assets/cards2.svg"/>
          <span>
            <img class="img mob" src="../assets/decor6Mob.svg"/>
            <div>
              {{$t('SIGN UP')}}
            </div>
          </span>
        </div>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }" slim class="form">
          <form @submit.prevent="handleSubmit(submit)">
            <ValidationProvider mode="passive" rules="required" v-slot="{ errors }" vid="name" slim>
              <label class="label">
                <div class="desc">{{$t('NAME')}}</div>
                <input required name="name" v-model="name" type="text"/>
                <div v-if="errors.length" class="error">
                  {{ errors[0] }}
                </div>
              </label>
            </ValidationProvider>
            <!--            <ValidationProvider rules="required" v-slot="{ errors }" vid="surname" slim>-->
            <!--              <label class="label">-->
            <!--                <div class="desc">SURNAME</div>-->
            <!--                <input required name="email" v-model="surname" type="text"/>-->
            <!--                <div v-if="errors.length" class="error">-->
            <!--                  {{ errors[0] }}-->
            <!--                </div>-->
            <!--              </label>-->
            <!--            </ValidationProvider>-->
            <ValidationProvider mode="passive" rules="required|email" v-slot="{ errors }" vid="email" slim>
              <label class="label">
                <div class="desc">{{$t('EMAIL')}}</div>
                <input required name="email" v-model="email" type="text"/>
                <div v-if="errors.length" class="error">
                  {{ errors[0] }}
                </div>
              </label>
            </ValidationProvider>
            <ValidationProvider mode="passive" rules="required" v-slot="{ errors }" vid="phone" slim>
              <label class="label">
                <div class="desc">{{$t('PHONE NUMBER')}}</div>
                <input required name="phone" v-model="phone" type="text"/>
                <div v-if="errors.length" class="error">
                  {{ errors[0] }}
                </div>
              </label>
            </ValidationProvider>
            <ValidationProvider mode="passive" rules="required|min:5" vid="password" v-slot="{ errors }" slim>
              <label class="label">
                <div class="desc">{{$t('PASSWORD')}}</div>
                <input required name="password" v-model="password" type="password"/>
                <div v-if="errors.length" class="error">
                  {{ errors[0] }}
                </div>
              </label>
            </ValidationProvider>

            <ValidationProvider mode="passive" rules="required|confirmed:password" :customMessages="{confirmed: 'Password does not match'}" v-slot="{ errors }" vid="password_confirmation" slim>
              <label class="label">
                <div class="desc">{{$t('CONFIRM PASSWORD')}}</div>
                <input required name="password_confirmation" v-model="password_confirmation" type="password"/>
                <div v-if="errors.length" class="error">
                  {{ errors[0] }}
                </div>
              </label>
            </ValidationProvider>

            <div class="link-container">
              <img class="decor7" src="../assets/decor7.svg"/>
            </div>
            <div v-if="error" class="general-error">
              {{ error }}
            </div>

            <ValidationProvider mode="passive" slim :rules="{ required: { allowFalse: false } }" vid="agree" v-slot="{ errors }">
              <div class="agree">
                <checkbox class="desc" v-model="agree">{{$t('I agree to the Terms&Conditions and Privacy Policy')}}</checkbox>
                <div v-if="errors.length" class="error">
                  {{$t('Please agree with rules')}}
                </div>
              </div>
            </ValidationProvider>

            <div class="button-container">
              <img class="decor8" src="../assets/plane.svg"/>
              <button type="submit" class="button">{{$t('SIGN UP')}}</button>
            </div>
            <div class="center-link-container">
              <router-link to="/login" class="desc">{{$t('HAVE AN ACCOUNT? LOGIN!')}}</router-link>
            </div>
            <div class="center-link-container">
              <router-link to="/" class="desc">{{$t('Back to homepage')}}</router-link>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </main>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import axios from "@/axios";
import router from "@/router";
import {mapActions} from "vuex";
import Checkbox from "@/components/Checkbox.vue";

export default {
  name: "Register",
  props: [],
  components: {
    Checkbox,
    ValidationProvider,
    ValidationObserver
  },
  data: function () {
    return {
      name: '',
      surname: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      error: '',
      agree: false
    };
  },
  mounted() {
  },
  methods: {
    submit() {
      const self = this;

      axios.post('register', {
        name: this.name,
        // surname: this.surname,
        password: this.password,
        password_confirmation: this.password_confirmation,
        email: this.email,
        phone: this.phone
      }).then(({data}) => {
        if (data.status === 'OK') {
          self.getProfile();
          router.push('/')
        } else if (data.status === 'ERROR') {
          self.error = data.message;
        }
      }).catch(({response}) => {
        if (response.status === 400) {

          console.log(response)

          if (response.data.message) {
            self.error = response.data.message
          } else if (response.data.errors) {
            this.$refs.form.setErrors(response.data.errors);
          }
        }
      })
    },
    ...mapActions('user', ['getProfile'])
  },
};
</script>
