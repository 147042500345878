import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import rooms from "./modules/rooms";
import topics from "./modules/topics";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        app,
        user,
        rooms,
        topics
    },
    strict: debug,
})