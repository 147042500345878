<template>
  <main class="main inside-page form-page recover-page">
    <div class="section recover-section">
      <div class="wrapper">
        <img class="eyes" src="../assets/eyes2.svg"/>
        <div class="title">
          <img class="img" src="../assets/decor6Long.svg"/>
          <img class="img mob" src="../assets/decor6LongMob.svg"/>
          <img class="cards" src="../assets/cards2.svg"/>
          <span>{{$t('RESTORE PASSWORD')}}</span>
        </div>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }" vid: slim class="form">
          <form @submit.prevent="submit" class="form">
            <ValidationProvider rules="required:confirmed:password_confirmation" v-slot="{ errors }" vid="password" slim>
              <label class="label">
                <div class="desc">{{$t('ENTER YOUR PASSWORD')}}</div>
                <input v-model="password" name="password" type="password"/>
                <div v-if="errors.length" class="error">
                  {{ errors[0] }}
                </div>
              </label>
            </ValidationProvider>
            <ValidationProvider rules="required" v-slot="{ errors }" vid="password_confirmation" slim>
              <label class="label">
                <div class="desc">{{$t('PLEASE CONFIRM PASSWORD')}}</div>
                <input v-model="password_confirmation" name="password" type="password"/>
                <div v-if="errors.length" class="error">
                  {{ errors[0] }}
                </div>
              </label>
            </ValidationProvider>
            <div class="link-container">
              <img class="decor7" src="../assets/decor7.svg"/>
            </div>
            <div class="button-container">
              <img class="decor8" src="../assets/decor8.svg"/>
              <button type="submit" class="button">{{$t('RESTORE PASSWORD')}}</button>
            </div>
            <div class="center-link-container">
              <router-link to="/register" class="desc">{{$t('DONT HAVE AN ACCOUNT? CREATE ONE!')}}</router-link>
            </div>
            <div class="center-link-container">
              <router-link to="/" class="desc">{{$t('Back to homepage')}}</router-link>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "@/axios";
import {mapActions} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: "Recover",
  props: [],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data: function () {
    return {
      password: '',
      password_confirmation: ''
    };
  },
  mounted() {
  },
  methods: {
    submit() {
      const self = this;

      axios.post('user/password-reset', {
        password: this.password_confirmation,
        password_confirmation: this.password_confirmation,
        token: this.$route.query.token,
        email: this.$route.query.email
      }).then(({data}) => {
        if (data.status === 'OK') {
          self.showNotification({text: this.$t("Password successfully restored!")})
          self.$router.push('/login');
        } else if (data.status === 'ERROR') {
          self.showNotification({text: data.message})
        }
      }).catch(({response}) => {
        if (response.data.status === 'ERROR') {
          self.showNotification({text: response.data.message})
        }
      })
    },
    ...mapActions('app', ['showNotification'])
  },
};
</script>
