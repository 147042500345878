import axios from "axios";
import store from "@/store";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: true,
  timeout: 5000, // set the default timeout for requests to 5 seconds
})

instance.interceptors.response.use(response => {
  return response; // Просто возвращаем ответ, если все в порядке
}, async error => {
  if (error.response && error.response.status === 401) {
    // Если получен ответ с кодом 401 (Unauthorized), разлогиниваем пользователя
    await store.dispatch('user/logout', {callApi: false, pushToLogin: false});
  }
  return Promise.reject(error);
});

export default instance;
