<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <div
          v-if="authenticated"
          class="mob menu-icon"
          @click="toggleMenu"
          :class="{ active: menuVisible }"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="header-container">
          <Header v-if="$route.name != 'Login' && $route.name != 'Register' && $route.name != 'Recover'"/>
        </div>
        <router-view :chatNoteModalIsVisible="chatNoteModalIsVisible" />
        <div class="footer-container">
          <Footer />
        </div>
        <transition name="fade">
          <ChatNoteModal v-if="chatNoteModalIsVisible" />
        </transition>
        <transition name="fade">
          <NotificationModal v-if="notificationShown" />
        </transition>
        <transition name="fade">
          <ConfirmationModal v-if="confirmationShown" />
        </transition>
        <transition name="fade">
          <PersonalInfoModal v-if="personalInfoModalIsVisible" />
        </transition>
        <transition name="fade">
          <TopUpModal v-if="topUpModalIsVisible" />
        </transition>
        <transition name="fade">
          <RecoverSuccessModal @close="closeRecoverSuccessModal" v-if="recoverSuccessModalIsVisible" />
        </transition>
        <div 
          v-if="cookesModalIsVisible"
          class="modal-overlay" 
        >
          <div class="cookies-modal">
            <div class="title">
              {{ $t('Warm Cookie Welcome from us!') }}
            </div>
            <div class="desc">
              {{ $t('Thank you for visiting Gossipy! Our platform leverages cookies and related technologies to enhance your journey with us. Essential cookies, vital for our website\'s functionality, are set by default. However, we also offer Non-essential cookies designed to personalize your experience further — think analytics, performance improvements, and tailored advertising. Keen to learn more about our approach to cookies and how you can manage them? Dive into our') }}  
              <a 
                class="desc"
                @click="goToPage('cookie')" 
              >
              {{ $t('Cookie Notice') }}
              </a>
              {{$t('for the full scoop.') }}
            </div>
            <a 
              class="button link" 
              @click="acceptCookies"
            >
              {{ $t('Understand & Accept') }}
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import ChatNoteModal from "./components/ChatNoteModal.vue";
import NotificationModal from "./components/NotificationModal.vue";
import PersonalInfoModal from "./components/PersonalInfoModal.vue";
import TopUpModal from "./components/TopUpModal.vue";
import {mapActions, mapGetters} from "vuex";
import router from "@/router";
import RecoverSuccessModal from "./components/RecoverSuccessModal.vue";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import i18n from "@/i18n";

export default {
  name: "app",
  components: {
    Header,
    Footer,
    ChatNoteModal,
    NotificationModal,
    ConfirmationModal,
    PersonalInfoModal,
    TopUpModal,
    RecoverSuccessModal
  },
  data: function () {
    return {
      pageLoaded: false,
      menuVisible: false,
      nav: [
        {
          title: "Home",
          link: "/",
        },
        {
          title: "Marketplace",
          link: "/products/all",
        },
      ],
      isAuth: true,
      channelStatus: true,
      chatNoteModalIsVisible: false,
      currentTopicId: null,
      reportModalIsVisible: false,
      personalInfoModalIsVisible: false,
      topUpModalIsVisible: false,
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      recoverSuccessModalIsVisible: '',
      cookesModalIsVisible: false,
      socialLinks: []
    };
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      this.closeChatNoteModal();
    },
    authenticated(newValue) {
      if (newValue === false && this.$route.meta.requiresAuth) {
        this.$router.push('/login')
      }
    },
  },
  methods: {
		convertTitle(title) {
			if (typeof title === 'string') {
				return title.replace(/ /g, '_').toLowerCase();
			}
			return title;
		},
    getSocialLinks() {
      this.$http.get(process.env.VUE_APP_API + 'social-links')
        .then(({ data }) => {
          this.socialLinks = data.payload;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');

      if (!cookiesAccepted) {
          this.cookesModalIsVisible = true;
      }
    },
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.cookesModalIsVisible = false;
    },
    fetchTranslations() {
      this.$http.get(process.env.VUE_APP_API + 'localizations')
          .then((res) => {
            i18n.setLocaleMessage('en', res.data)
            i18n.locale = 'en';
          })
    },
    goToPage(item) {
      let newItem;
      if (item == 'privacy') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('privacy')) {
            newItem = this.textPageList[i];
          } 
        }
      } else if (item == 'terms') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('terms')) {
            newItem = this.textPageList[i];
          } 
        }
      } else if (item == 'cookie') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('cookie')) {
            newItem = this.textPageList[i];
          } 
        }
      } else {
        newItem = item
      }
      this.$router.push({
        path: '/pages/' + this.convertTitle(newItem.title),
        params: { title: this.convertTitle(newItem.title), id: newItem.id }
      });
      this.getTextPageContent(newItem.id);
      this.closeSignUp();
    },
    getTextPageContent(id) {
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';
      this.$http.get(process.env.VUE_APP_API + 'static-pages/' + id)
      .then((res) => {
        this.textPageTitle = res.data.payload.title;
        this.textPageContent = res.data.payload.content;
        this.textPageHeader = res.data.payload.header;
      })
    },
    getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    leaveChatNoteModal() {
      this.closeChatNoteModal();
      router.push({name: 'Rooms'})
    },
    closeChatNoteModal() {
      this.chatNoteModalIsVisible = false;
      this.currentTopicId = null;
      document.body.style.overflow = "auto";
    },
    openChatNoteModal(topicId) {
      this.chatNoteModalIsVisible = true;
      this.currentTopicId = topicId;
      document.body.style.overflow = "hidden";
    },
    openReportModal() {
      this.reportModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeReportModal() {
      this.reportModalIsVisible = false;
      document.body.style.overflow = "auto";
    },
    openPersonalInfoModal() {
      this.personalInfoModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closePersonalInfoModal() {
      this.personalInfoModalIsVisible = false;
      document.body.style.overflow = "auto";
    },
    openTopUpModal() {
      this.topUpModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeTopUpModal() {
      this.topUpModalIsVisible = false;
      document.body.style.overflow = "auto";
    },
    openRecoverSuccessModal() {
      this.recoverSuccessModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeRecoverSuccessModal() {
      this.recoverSuccessModalIsVisible = false;
      document.body.style.overflow = "auto";
    },
    ...mapActions('user', ['getProfile']),
    ...mapActions('app', ['getSettings', 'getStaticPages'])
  },
  async created() {
    this.getSettings()
    this.getStaticPages()
    await this.getProfile();
  },
  mounted: async function () {
    this.getSocialLinks();
    this.cookiesModal();
    this.fetchTranslations();
    this.pageLoaded = true;
    let self = this;
    window.addEventListener("resize", self.menuHeight);
    self.menuHeight();
    this.getTextPages();
  },
  computed: {
    ...mapGetters('user', ['authenticated']),
    ...mapGetters('app', ['notificationShown', 'confirmationShown']),
    ...mapGetters('user', ['authenticated'])
  },
};
</script>
