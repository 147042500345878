<template>
  <main :class="['main inside-page form-page create-topic-page', {'status': $parent.channelStatus}]">
    <div class="section create-topic-section">
      <div class="wrapper">
        <div class="title">
          <img class="img" src="../assets/decor14.svg"/>
          <img class="img mob" src="../assets/decor6Mob.svg"/>
          <img class="cards" src="../assets/cards2.svg"/>
          <span>{{$t('CREATE TOPIC')}}</span>
        </div>
        <ValidationObserver ref="form" slim v-slot="{handleSubmit, valid, pristine}">
          <form @submit.prevent="handleSubmit(submit)" class="form">

            <ValidationProvider name="Name" rules="required" v-if="!isChannel" v-slot="{errors}" slim vid="title">
              <label class="label">
                <div class="desc">{{$t('NAME OF TOPIC')}}</div>
                <textarea name="name" v-model="title" type="text"></textarea>
                <div v-if="errors.length" class="error">
                  {{ errors[0] }}
                </div>
              </label>
            </ValidationProvider>

            <ValidationProvider rules="required" v-if="isChannel" v-slot="{errors}" slim vid="title">
              <label class="label" v-if="isChannel">
                <div class="desc">{{$t('NAME OF TOPIC')}}</div>
                <input name="name" v-model="title" type="text">
                <div class="desc">0/50 symbols</div>
                <div v-if="errors.length" class="error">
                  {{ errors[0] }}
                </div>
              </label>
            </ValidationProvider>

            <label class="label" v-if="isChannel">
              <div class="desc">{{$t('SELECT TEXT LOOK')}}</div>
              <select v-model="topicColor" :class="'select-' + topicColor">
                <option selected value="">{{$t('WHITE')}}</option>
                <option value="blue">{{$t('BLUE')}}</option>
                <option value="red">{{$t('RED')}}</option>
                <option value="purple">{{$t('PURPLE')}}</option>
              </select>
            </label>
            <div class="link-container">
              <img class="decor7" src="../assets/decor7.svg"/>
            </div>
            <div v-if="error" class="general-error">
              {{ error }}
            </div>
            <div class="button-container">
              <img class="decor8" src="../assets/decor8.svg"/>
              <div v-if="!isChannel" class="desc">0/50 symbols</div>
              <div v-if="isChannel" class="desc"></div>
              <button type="submit" v-if="cost !== null" class="button">
                <span>{{$t('CREATE TOPIC')}}</span>
                <span>{{$t('FOR')}} {{ cost }} {{$t('POINTS')}}</span>
              </button>
            </div>
            <div class='center-link-container'>
              <router-link to="/rooms" class="desc" v-text="'<- BACK'"></router-link>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </main>
</template>

<script>

import router from "@/router";
import axios from "@/axios";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: "CreateTopic",
  props: [],
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: function () {
    return {
      roomId: null,
      title: '',
      topicColor: '',
      error: '',
      cost: null
    };
  },
  created() {
    this.getTopicCreationCost();
  },
  async mounted() {
    if (!this.rooms.length) {
      await this.getRooms();
    }

    if (!this.getRoomId()) {
      await router.push({name: 'Rooms'})
    }
  },
  methods: {
    getRoomId() {
      const roomSlug = this.$route.params.name;
      const room = this.rooms.find((room) => roomSlug === room.slug);

      if (room) {
        this.roomId = room.id
        return this.roomId;
      }

      return null;
    },
    submit() {
      const self = this;
      const data = {
        room_id: this.roomId,
        title: this.title
      };

      if (this.isChannel && this.topicColor) {
        data['color'] = this.topicColor;
      }

      axios.post('topics/create', data).then(({data}) => {
        if (data.status === 'OK') {
          self.SET_TOPIC(data.payload.topic.id);
          self.SET_BALANCE(data.payload.balance)
          router.push({name: 'Chat'})
        }
      }).catch(({response}) => {
        if (response.status === 400) {
          if (response.data.message) {
            self.error = response.data.message
          } else if (response.data.errors) {
            this.$refs.form.setErrors(response.data.errors);
          }
        }
      })
    },
    getTopicCreationCost() {
      const self = this;

      axios.get('cost/topic').then(({data}) => {
        if (data.status === 'OK') {
          self.cost = data.payload;
        }
      })
    },
    ...mapMutations('topics', ['SET_TOPIC']),
    ...mapMutations('user', ['SET_BALANCE']),
    ...mapActions('rooms', ['getRooms'])
  },
  computed: {
    ...mapGetters('rooms', ['rooms']),
    ...mapGetters('user', ['isChannel']),
  }
};
</script>
