import { extend } from 'vee-validate';
import { required, email, confirmed, min, max, min_value, numeric } from 'vee-validate/dist/rules';

// No message specified.
extend('email', email);
extend('required', required);
extend('confirmed', confirmed);
extend('min', min);
extend('max', max);
extend('min_value', min_value);
extend('numeric', numeric);