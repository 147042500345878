<template>
  <main class="main home-page">
    <div class="section hero-section">
      <div class="wrapper">
        <img class="circles" src="../assets/circles.svg" />
        <img class="circles mob" src="../assets/circlesMob.svg" />
        <div class="title">
          <img class="img" src="../assets/title1.svg" />
          <img class="img mob" src="../assets/title1Mob.svg" />
        </div>
        <div class="desc">
          <img class="img" src="../assets/decor1.svg" />
          <img class="img mob" src="../assets/decor1Mob.svg" />
          <span>
            {{$t('Welcome to our little corner, where  you can interact with friends and random strangers, gossip about your secrets, fall in love and flirt, all completely anonymously.')}}
          </span>
        </div>
      </div>
    </div>
    <div class="section section-2">
      <div class="wrapper">
        <div class="title">
          <img class="cards" src="../assets/cards.svg" />
          <img class="img" src="../assets/title2.svg" />
          <img class="img mob" src="../assets/title2Mob.svg" />
        </div>
        <img class="eyes" src="../assets/eyes.svg" />
        <img class="eyes mob" src="../assets/eyesMob.svg" />
        <div class="desc">
          {{$t('Many of us struggle with freely expressing our thoughts, emotions, revelations, and secrets. Not everyone comprehends our jokes and ideas, and on our favorite social networks, friends, family, and colleagues often judge us out of envy or their own insecurities.')}}
        </div>
      </div>
    </div>
    <div class="section section-3">
      <div class="wrapper">
        <div class="list">
          <div class="item">
            <img class="decor3" src="../assets/decor3.svg" />
            <img class="decor2" src="../assets/decor2.svg" />
            <img class="decor2 mob" src="../assets/decor2Mob.svg" />
            <div class="desc">
              <span>
                {{$t('Modern social networks and messengers have become overly personalized, hindering global communication without undue criticism. It feels as if we\'re constantly under the scrutiny of an environment that spies on our every action, acquaintance, or photo.')}}
              </span>
            </div>
          </div>
          <div class="item">
            <img class="decor5" src="../assets/decor3.svg" />
            <img class="decor4" src="../assets/decor4.svg" />
            <img class="decor4 mob" src="../assets/decor4Mob.svg" />
            <div class="desc">
              <span>
                {{$t('Enter incognito mode with JoyNight – a truly anonymous app for safe and private group communication, helping mask your identity, connect with new friends based on shared interests, and meet interesting people online.')}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-4">
      <div class="wrapper">
        <div class="desc">
          <img class="star" src="../assets/star.svg" />
          <img class="heart" src="../assets/heart.svg" />
          {{$t('Now you can interact with friends and random strangers, gossip about your secrets, fall in love, and flirt, all completely anonymously.')}}
        </div>
      </div>
    </div>
    <div class="section section-5">
      <div class="wrapper">
        <img class="ourRules" src="../assets/ourRules.png" />
        <img class="ourRules mob" src="../assets/ourRulesMob.svg" />
        <div class="list-container">
          <img class="decor5" src="../assets/decor5.svg" />
          <div class="list">
            <div class="item desc">{{$t('no names')}}</div>
            <div class="item desc">{{$t('no spam')}}</div>
            <div class="item desc">{{$t('no history')}}</div>
            <div class="item desc">{{$t('no ads')}}</div>
            <div class="item desc">{{$t('no danger')}}</div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: "Home",
  props: [],
  components: {
  },
  data: function () {
    return {
    };
  },
  mounted() {
  },
  methods: {
    
  },
};
</script>
