<template>
  <main class="main inside-page form-page login-page">
    <div class="section login-section">
      <div class="wrapper">
        <img class="eyes" src="../assets/eyes2.svg"/>
        <div class="title">
          <img class="img" src="../assets/decor6.svg"/>
          <img class="cards" src="../assets/cards2.svg"/>
          <span>
            <img class="img mob" src="../assets/decor6Mob.svg"/>
            <div>
              {{$t('LOGIN')}}
            </div>
          </span>
        </div>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }" slim>
          <form @submit.prevent="handleSubmit(submit)" class="form">
            <ValidationProvider mode="passive" slim v-slot="{ errors }" rules="required|email" vid="email">
              <label class="label">
                <div class="desc">{{$t('EMAIL')}}</div>
                <input v-model="email" required name="email" type="email"/>
                <div v-if="errors.length" class="error">
                  {{ errors[0] }}
                </div>
              </label>
            </ValidationProvider>
            <ValidationProvider mode="passive" slim v-slot="{ errors }" rules="required" vid="password">
              <label class="label">
                <div class="desc">{{$t('PASSWORD')}}</div>
                <input v-model="password" required name="password" type="password"/>
                <div v-if="errors.length" class="error">
                  {{ errors[0] }}
                </div>
              </label>
            </ValidationProvider>
            <div v-if="error" class="general-error">
              {{ error }}
            </div>

            <div class="link-container">
              <img class="decor7" src="../assets/decor7.svg"/>
              <router-link to="/recover" class="desc">{{$t('FORGOT PASSWORD?')}}</router-link>
            </div>
            <div class="button-container">
              <img class="decor8" src="../assets/decor8.svg"/>
              <button type="submit" class="button">{{$t('LOGIN')}}</button>
            </div>
            <div class="center-link-container">
              <router-link to="/register" class="desc">{{$t('DONT HAVE AN ACCOUNT? CREATE ONE!')}}</router-link>
            </div>
            <div class="center-link-container">
              <router-link to="/" class="desc">{{$t('Back to homepage')}}</router-link>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </main>
</template>

<script>

import axios from "@/axios";
import router from "@/router";
import {mapActions} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: "Login",
  props: [],
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: function () {
    return {
      email: '',
      password: '',
      error: ''
    };
  },
  mounted() {
  },
  methods: {
    submit() {
      const self = this;

      axios.post('login', {
        email: this.email,
        password: this.password
      }).then(({data}) => {
        if (data.status === 'OK') {
          self.getProfile();
          router.push('/')
        } else if (data.status === 'ERROR') {
          self.error = data.message;
        }
      }).catch(({response}) => {
        if (response.status === 400) {
          if (response.data.message) {
            self.error = response.data.message
          } else if (response.data.errors) {
            this.$refs.form.setErrors(response.data.errors);
          }
        }
      })
    },
    ...mapActions('user', ['getProfile'])
  },
};
</script>
