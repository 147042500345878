<template>
  <main class="main inside-page form-page text-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="page-inside-top">
          <transition name="fade">
            <div
                v-if="textPageTitle"
                class="wrapper"
            >
              <div class="title">
                <img class="img" src="../assets/decor15.svg"/>
                <img class="img mob" src="../assets/decor15.svg"/>
                <span>{{ textPageTitle }}</span>
              </div>
              <div class="desc-container">
                <div
                    class="desc"
                    v-html="textPageContent"
                />
              </div>
            </div>
          </transition>
        </div>
        <div class="center-link-container">
          <router-link to="/" class="desc">{{ $t('Back to homepage') }}</router-link>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'TextPage',
  components: {},
  props: [],
  data: function () {
    return {
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: ''
    }
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.getTextPageContent(this.$route.params.id);
      } else {
        this.$http.get(process.env.VUE_APP_API + 'static-pages')
        .then((res) => {
          let newList = res.data.payload;
          let newItem = newList.filter(item => item.title.replace(/ /g, '_').toLowerCase() == this.$route.params.title)
          this.getTextPageContent(newItem[0].id);
        })
      }
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getTextPageContent(this.$route.params.id);
    } else {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        let newList = res.data.payload;
        let newItem = newList.filter(item => item.title.replace(/ /g, '_').toLowerCase() == this.$route.params.title)
        this.getTextPageContent(newItem[0].id);
      })
    }
  },
  methods: {
    getTextPageContent(id) {
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';
      this.$http.get(process.env.VUE_APP_API + 'static-pages/' + id)
          .then((res) => {
            this.textPageTitle = res.data.payload.title;
            this.textPageContent = res.data.payload.content;
            this.textPageHeader = res.data.payload.header;
          })
    }
  }
}
</script>