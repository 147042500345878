<template>
  <header class="header">
    <div :class="['wrapper is-auth-wrapper', {'menuVisible': $parent.menuVisible}]" v-if="authenticated">
      <div class="left"></div>
      <div class="center">
        <router-link to="/" class="title nav-link">{{ $t('HOME') }}</router-link>
        <router-link to="/rooms" class="title nav-link">{{ $t('ROOMS') }}</router-link>
      </div>
      <div class="right">
        <router-link :to="{name: 'Profile'}" class="title">{{ $t('PROFILE') }}</router-link>
        <img class="circle" src="../assets/circle.svg" />
      </div>
    </div>
    <div class="wrapper" v-else>
      <router-link :to="{name: 'Login'}" class="button button-login">{{ $t('Login') }}</router-link>
      <router-link :to="{name: 'Register'}" class="button button-signup">{{ $t('Sign UP') }}</router-link>
    </div>
  </header>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "Header",
  props: [],
  data: function () {
    return {};
  },
  methods: {
    
  },
  computed: {
    ...mapGetters('user', ['authenticated'])
  }
};
</script>
