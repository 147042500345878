import axios from "@/axios";

const state = {
    settings: {},
    topUpBundles: [],
    bundleId: null,
    notificationModal: {
        text: '',
        show: false,
        ok: false
    },
    confirmationModal: {
        show: false,
        text: '',
        resolve: null,
        reject: null
    },
    staticPages: []
}

const getters = {
    settings: (state) => (name = null) => {
        if (name !== null) {
            return state.settings[name]
        }

        return state.settings;
    },
    bundles: (state) => (id = null) => {
        if (id !== null) {
            return state.topUpBundles.find((bundle) => bundle.id === id)
        }

        return state.topUpBundles;
    },
    bundleId: (state) => state.bundleId,
    notification: (state) => state.notificationModal,
    notificationText: ({notificationModal}) => notificationModal.text,
    notificationOk: ({notificationModal}) => notificationModal.ok,
    notificationShown: ({notificationModal}) => notificationModal.show,
    confirmationText: ({confirmationModal}) => confirmationModal.text,
    confirmationShown: ({confirmationModal}) => confirmationModal.show,
    staticPages: ({staticPages}) => staticPages
}

const actions = {
    getSettings: async ({commit}) => {
        try {
            const {data} = await axios.get(process.env.VUE_APP_API + 'settings');
            commit('SET_SETTINGS', data.payload)
            // eslint-disable-next-line no-empty
        } catch (e) {
        }
    },
    getBundles: async ({commit}) => {
        commit('SET_TOP_UP_BUNDLE_ID', null)

        return axios.get('bundles/index').then(({data}) => {
            if (data.status === 'OK') {
                commit('SET_TOP_UP_BUNDLES', data.payload);
            }
        })
    },
    getStaticPages({commit}) {
        return axios.get('static-pages').then(({data}) => {
            if (data.status === 'OK') {
                commit('SET_STATIC_PAGES', data.payload);
            }
        })
    },
    showNotification: ({commit}, {text, ok = false}) => {
        commit('SET_NOTIFICATION_MODAL_TEXT', text)
        commit('SET_NOTIFICATION_MODAL_OK', ok)
        commit('SET_NOTIFICATION_MODAL_SHOW', true)
    },
    closeNotification: ({commit}) => {
        commit('SET_NOTIFICATION_MODAL_SHOW', false)
    },
    async showConfirmationModal({commit}, message) {
        return new Promise((resolve, reject) => {
            commit('OPEN_CONFIRMATION_MODAL', {message, resolve, reject});
        });
    },
    async closeConfirmationModal({commit}) {
        commit('CLOSE_CONFIRMATION_MODAL');
    },
    // waitConfirmationModalResult({ commit }, { resolve, reject }) {
    //     // Ожидаем результата и возвращаем его
    //     store.watch(
    //         (state) => state.modal.isOpen,
    //         (isOpen) => {
    //             if (!isOpen) {
    //                 resolve(true);
    //             }
    //         }
    //     );
    // }
}

const mutations = {
    SET_SETTINGS: (state, settings) => (state.settings = settings),
    SET_TOP_UP_BUNDLES: (state, bundles) => (state.topUpBundles = bundles),
    SET_TOP_UP_BUNDLE_ID: (state, id) => (state.bundleId = id),
    SET_NOTIFICATION_MODAL_TEXT: (state, text) => (state.notificationModal = {...state.notificationModal, text}),
    SET_NOTIFICATION_MODAL_OK: (state, ok) => (state.notificationModal = {...state.notificationModal, ok}),
    SET_NOTIFICATION_MODAL_SHOW: (state, show) => (state.notificationModal = {...state.notificationModal, show}),
    SET_STATIC_PAGES: (state, staticPages) => (state.staticPages = staticPages),
    OPEN_CONFIRMATION_MODAL: (state, {message, resolve, reject}) => {
        state.confirmationModal = {
            show: true,
            text: message,
            resolve,
            reject
        };
    },
    CLOSE_CONFIRMATION_MODAL: (state) => {
        state.confirmationModal = {
            ...state.confirmationModal,
            show: false,
            resolve: null,
            reject: null
        };
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}