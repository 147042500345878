<template>
  <footer class="footer">
    <div class="wrapper">
		<div class="left">
			<div class="desc">{{ $t('This website is operated by') }}</div>
			<div class="desc">{{$t('REGISTRY CODE')}} </div>
			<div class="desc">
				{{$t('LEGAL ADDRESS') }}
			</div>
			<div class="desc small">
                © {{ new Date().getFullYear() }} Gossipy. {{ $t('All Rights Reserved') }}
			</div>
			<div class="list">
				<img class="img mc" src="../assets/methods/mc.png" />
				<img class="img visa" src="../assets/methods/visa.png" />
				<img class="img ds" src="../assets/methods/3ds.png" />
			</div>
		</div>
		<div class="center">
			<div class="list">
				<router-link 
					v-for="({title, id}) in staticPages" 
					:key="id" 
					:to="generatePathWithParams(title, id)" 
					class="desc"
				>
					{{ title }}
				</router-link>
			</div>
		</div>
		<div class="right">
			<div class="name">
				<img class="img" src="../assets/logo.svg" />
			</div>
			<img class="footer-text" src="../assets/footerText.svg" />
			<div class="list">
				<a v-for="link in $parent.socialLinks" :key="link.image" :href="link.link" target="_blank" class="item">
					<img :src="link.img" :alt="link.link" class="img"/>
				</a>
				<div class="desc small">{{ settings('support_email') }}</div>
			</div>
		</div>
    </div>
  </footer>
</template>

<script>

import {mapGetters} from "vuex";

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
		}
	},
	methods: {
		convertTitle(title) {
			if (typeof title === 'string') {
				return title.replace(/ /g, '_').toLowerCase();
			}
			return title;
		},
		generatePathWithParams(title, id) {
			const convertedTitle = this.convertTitle(title);
			return {
				name: 'TextPage',
				params: { title: convertedTitle, id: id }
			};
		}
	},
	mounted() {
    
  },
  computed: {
    ...mapGetters('app', ['staticPages', 'settings'])
  }
}
</script>
