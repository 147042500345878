import axios from '@/axios';
import router from "@/router";

const state = {
    authenticated: null,
    profile: {}
}

const getters = {
    authenticated: state => state.authenticated,
    profile: state => state.profile,
    balance: ({profile: { balance }}) => balance,
    isChannel: ({profile: { is_channel }}) => is_channel,
    channelExpireAt: ({profile: { channel_expire_at }}) => channel_expire_at
}

const actions = {
    getProfile: async ({commit}) => {
        try {
            const {data} = await axios.get(process.env.VUE_APP_API + 'user/profile');
            commit('SET_AUTHENTICATED', true);
            commit('SET_PROFILE', data);
            // eslint-disable-next-line no-empty
        } catch ({response: {status}}) {}
    },
    logout({commit}, {callApi = false, pushToLogin = true}) {
        if (callApi) {
            console.log('To do logout');
        }

        commit('SET_AUTHENTICATED', false);
        commit('SET_PROFILE', {})

        if (pushToLogin) {
            router.push({name: 'Login'})
        }
    }
}

const mutations = {
    SET_AUTHENTICATED: (state, auth) => (state.authenticated = auth),
    SET_PROFILE: (state, profile) => (state.profile = profile),
    SET_BALANCE: (state, balance) => (state.profile = {...state.profile, balance}),
    SET_IS_CHANNEL: (state, isChannel) => (state.profile = {...state.profile, isChannel}),
    SET_CHANNEL_EXPIRE_AT: (state, channel_expire_at) => (state.profile = {...state.profile, channel_expire_at}),
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}