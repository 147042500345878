<template>
  <main class="main inside-page form-page rooms-page">
    <div class="section topics-section">
      <div class="wrapper">
        <div class="top">
          <div class="title">
            <img class="img" src="../assets/decor12.svg" />
            <img class="img mob" src="../assets/decor12Mob.svg" />
            <span v-if="room">{{ room.title === 'all' ? 'All Rooms' : room.title }}</span>
            <div v-if="room" class="img-container">
              <img class="img" v-if="room.title !== 'all'" :src="room.image_url" />
              <img class="img" v-else src="@/assets/arrow.svg"/>
            </div>
          </div>
          <div class="right">
            <a v-if="room && room.title !== 'all'" href="#" @click.prevent="newTopic" class="button button-blue">{{ $t('CREATE TOPIC') }}</a>
            <a href="#" @click.prevent="startRandomTopic" class="button button-red">{{ $t('START RANDOM CHAT') }}</a>
          </div>
        </div>
        <div class="no-topics" v-if="topics.length == 0">
          <div class="title">
            <img class="decor19" src="../assets/decor19.png" />
            <span>{{ $t('Oops, looks like there are no topics available right now.') }}</span>
          </div>
          <div class="desc">{{ $t("maybe you'd like to create one?") }}</div>
        </div>  
        <div class="topics-list">
          <a href="#"
             @click.prevent="selectTopic(item)"
            :class="[
              'topics-list__item desc ' + item.highlight_color,
              {'size1': item.title.length > 49},
              {'size2': item.title.length < 50 && item.title.length > 34},
              {'size3': item.title.length < 35 && item.title.length > 31},
              {'size5': item.title.length < 32 && item.title.length > 28},
              {'size4': item.title.length < 29 && item.title.length > 25},
              {'size6': item.title.length < 26 && item.title.length > 22},
              {'size7': item.title.length < 23},
              {'bold': item.is_highlighted}
            ]" 
            v-for="(item, i)  in topics" :key="i"
          >
            <img v-if="item.title.length > 49 && !item.highlight_color" class="img" src="../assets/topicsBg/png/1.png" />
            <img v-if="item.title.length > 49 && item.highlight_color == 'purple'" class="img" src="../assets/topicsBg/png/1purple.png" />
            <img v-if="item.title.length > 49 && item.highlight_color == 'blue'" class="img" src="../assets/topicsBg/png/1blue.png" />
            <img v-if="item.title.length > 49 && item.highlight_color == 'red'" class="img" src="../assets/topicsBg/png/1red.png" />
            
            <img v-if="item.title.length < 50 && item.title.length > 34 && !item.highlight_color" class="img" src="../assets/topicsBg/png/2.png" />
            <img v-if="item.title.length < 50 && item.title.length > 34 && item.highlight_color == 'purple'" class="img" src="../assets/topicsBg/png/2purple.png" />
            <img v-if="item.title.length < 50 && item.title.length > 34 && item.highlight_color == 'blue'" class="img" src="../assets/topicsBg/png/2blue.png" />
            <img v-if="item.title.length < 50 && item.title.length > 34 && item.highlight_color == 'red'" class="img" src="../assets/topicsBg/png/2red.png" />

            <img v-if="item.title.length < 35 && item.title.length > 31 && !item.highlight_color" class="img" src="../assets/topicsBg/png/3.png" />
            <img v-if="item.title.length < 35 && item.title.length > 31 && item.highlight_color == 'purple'" class="img" src="../assets/topicsBg/png/3purple.png" />
            <img v-if="item.title.length < 35 && item.title.length > 31 && item.highlight_color == 'blue'" class="img" src="../assets/topicsBg/png/3blue.png" />
            <img v-if="item.title.length < 35 && item.title.length > 31 && item.highlight_color == 'red'" class="img" src="../assets/topicsBg/png/3red.png" />

            <img v-if="item.title.length < 32 && item.title.length > 28 && !item.highlight_color" class="img" src="../assets/topicsBg/png/5.png" />
            <img v-if="item.title.length < 32 && item.title.length > 28 && item.highlight_color == 'purple'" class="img" src="../assets/topicsBg/png/5purple.png" />
            <img v-if="item.title.length < 32 && item.title.length > 28 && item.highlight_color == 'blue'" class="img" src="../assets/topicsBg/png/5blue.png" />
            <img v-if="item.title.length < 32 && item.title.length > 28 && item.highlight_color == 'red'" class="img" src="../assets/topicsBg/png/5red.png" />

            <img v-if="item.title.length < 29 && item.title.length > 25 && !item.highlight_color" class="img" src="../assets/topicsBg/png/4.png" />
            <img v-if="item.title.length < 29 && item.title.length > 25 && item.highlight_color == 'purple'" class="img" src="../assets/topicsBg/png/4purple.png" />
            <img v-if="item.title.length < 29 && item.title.length > 25 && item.highlight_color == 'blue'" class="img" src="../assets/topicsBg/png/4blue.png" />
            <img v-if="item.title.length < 29 && item.title.length > 25 && item.highlight_color == 'red'" class="img" src="../assets/topicsBg/png/4red.png" />

            <img v-if="item.title.length < 26 && item.title.length > 22 && !item.highlight_color" class="img" src="../assets/topicsBg/png/6.png" />
            <img v-if="item.title.length < 26 && item.title.length > 22 && item.highlight_color == 'purple'" class="img" src="../assets/topicsBg/png/6purple.png" />
            <img v-if="item.title.length < 26 && item.title.length > 22 && item.highlight_color == 'blue'" class="img" src="../assets/topicsBg/png/6blue.png" />
            <img v-if="item.title.length < 26 && item.title.length > 22 && item.highlight_color == 'red'" class="img" src="../assets/topicsBg/png/6red.png" />

            <img v-if="item.title.length < 23 && !item.highlight_color" class="img" src="../assets/topicsBg/png/7.png" />
            <img v-if="item.title.length < 23 && item.highlight_color == 'purple'" class="img" src="../assets/topicsBg/png/7purple.png" />
            <img v-if="item.title.length < 23 && item.highlight_color == 'blue'" class="img" src="../assets/topicsBg/png/7blue.png" />
            <img v-if="item.title.length < 23 && item.highlight_color == 'red'" class="img" src="../assets/topicsBg/png/7red.png" />

            <span>{{ item.title }}</span>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "@/axios";

export default {
  name: "Topics",
  props: [],
  components: {
  },
  data: function () {
    return {
    };
  },
  async mounted() {
    if (!this.rooms.length) {
      await this.getRooms();
    }

    await this.getTopics(this.$route.params.name);
  },
  methods: {
    ...mapActions('rooms', ['getRooms']),
    ...mapActions('topics', ['startRandomTopic', 'getTopics']),
    async selectTopic({id, is_chat_participant}) {
      if (is_chat_participant) {
        this.SET_TOPIC(id)
        await this.$router.push({name: 'Chat', params: {name: this.$route.params.name}})
      } else {
        await this.joinChat({roomSlug: this.$route.params.name, id});
      }
    },
    newTopic() {
      const self = this;

      axios.get('topics/allowed-new')
          .then(({data}) => {
            if (data.status === 'OK') {
              self.$router.push({name: 'CreateTopic'})
            }
          }).catch(({response}) => {
        if (response.status === 403 && response.data.message) {
          self.showNotification({text: response.data.message})
        }
      })
    },
    ...mapMutations('topics', ['SET_TOPIC']),
    ...mapActions('topics', ['joinChat']),
    ...mapActions('app', ['showNotification'])
  },
  computed: {
    ...mapGetters('rooms', ['rooms']),
    ...mapGetters('topics', ['topics', 'room']),
  }
};
</script>
