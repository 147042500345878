<template>
  <div class="modal topup-modal">
    <div class="overlay" @click="$parent.closeTopUpModal"></div>
    <div class="wrapper">
      <div class="container">
        <img class="close" @click="$parent.closeTopUpModal" src="./../assets/close.svg"/>
        <div class="form-container">
          <ValidationObserver v-if="step === 1" slim v-slot="{handleSubmit, valid, pristine}">
            <form @submit.prevent="handleSubmit(passFirstStep)">
              <div class="form">
                <div class="desc big">
                  <b>{{ $t('Top up your balance') }}</b>
                </div>
                <div class="method">
                  <div class="desc">{{ $t('PAYMENT METHOD') }}</div>
                  <div class="desc">
                    <b>{{ $t('CREDIT CARD') }}</b>
                  </div>
                </div>
                <ValidationProvider slim rules="required|numeric|min_value:0.01" name="Amount" vid="amount">
                  <label class="label">
                    <div class="desc">{{ $t('ENTER AMOUNT') }}</div>
                    <div class="input-container">
                      <input required name="amount" v-model="amount" type="number" value="5"/>
                      <div class="desc big">€</div>
                    </div>
                  </label>
                </ValidationProvider>
                <div class="button-list">
                  <div @click="amount = 5" class="button active">5 €</div>
                  <div @click="amount = 10" class="button">10 €</div>
                  <div @click="amount = 25" class="button">25 €</div>
                  <div @click="amount = 50" class="button">50 €</div>
                  <div @click="amount = 100" class="button">100 €</div>
                </div>
              </div>
              <div class="buttons">
                <div class="amount">
                  <div class="desc">{{ $t('YOU WILL GET') }}</div>
                  <div class="desc">
                    <b>{{ pointsAmount }} {{ $t('POINTS') }}</b>
                  </div>
                </div>
                <button type="submit" class="button red">{{ $t('NEXT') }}</button>
              </div>
              <div class="payment-methods">
                <img src="../assets/methods/visa.png" alt="">
                <img src="../assets/methods/mc.png" alt="">
              </div>
            </form>
          </ValidationObserver>
          <ValidationObserver v-else slim v-slot="{handleSubmit, valid, pristine}">
            <form class="step-2" @submit.prevent="handleSubmit(checkout)">
              <div class="form">
                <div class="desc big">
                  <b>{{ $t('Top up your balance') }}</b>
                </div>
                <ValidationProvider slim rules="required" name="Name" vid="name">
                  <label class="label">
                    <div class="desc">{{ $t('NAME') }}</div>
                    <input required name="name" v-model="name" type="text"/>
                  </label>
                </ValidationProvider>
                <ValidationProvider slim rules="required" name="Surname" vid="surname">
                  <label class="label">
                    <div class="desc">{{ $t('SURNAME') }}</div>
                    <input required name="surname" v-model="surname" type="text"/>
                  </label>
                </ValidationProvider>
                <ValidationProvider slim rules="required|email" name="Email" vid="email">
                  <label class="label">
                    <div class="desc">{{ $t('EMAIL') }}</div>
                    <input required name="email" v-model="email" type="email"/>
                  </label>
                </ValidationProvider>
                <ValidationProvider slim rules="required" name="Phone" vid="phone">
                  <label class="label">
                    <div class="desc">{{ $t('PHONE NUMBER') }}</div>
                    <input required name="phone" v-model="phone" type="text"/>
                  </label>
                </ValidationProvider>
                <ValidationProvider slim rules="required" name="Country" vid="country">
                  <label class="label">
                    <div class="desc">{{ $t('COUNTRY') }}</div>
                    <select required name="country" v-model="country">
                      <option disabled selected value="">{{ $t('Country') }}</option>
                      <option v-for="(item, i) in countryOptions" :value="item.id" :key="i">{{ item.title }}</option>
                    </select>
                  </label>
                </ValidationProvider>
                <ValidationProvider slim rules="required" name="City" vid="city">
                  <label class="label">
                    <div class="desc">{{ $t('CITY') }}</div>
                    <input required type="text" name="city" :placeholder="$t('City')" v-model="city"/>
                  </label>
                </ValidationProvider>

                <ValidationProvider slim rules="required" name="Address" vid="address">
                  <label class="label">
                    <div class="desc">{{ $t('ADDRESS') }}</div>
                    <input required type="text" name="address" :placeholder="$t('Address')" v-model="address"/>
                  </label>
                </ValidationProvider>

                <ValidationProvider slim rules="required" name="Post code" vid="postCode">
                  <label class="label">
                    <div class="desc">{{ $t('ZIP') }}</div>
                    <input required type="text" name="postcode" :placeholder="$t('Post Code')" v-model="postCode"/>
                  </label>
                </ValidationProvider>
                <label class="label">
                  <ValidationProvider mode="passive" slim :rules="{ required: { allowFalse: false } }" vid="agree" v-slot="{ errors }">
                    <div class="agree">
                      <checkbox class="desc" v-model="agree">
                        <span>
                          {{$t('I agree with')}}
                          <a 
                            class="desc"
                            @click="$parent.goToPage('privacy')" 
                          >
                          {{ $t('Privacy notice') }}
                          </a>
                          {{$t('and')}}
                          <a 
                            class="desc"
                            @click="$parent.goToPage('terms')" 
                          >
                          {{ $t('Terms and Conditions') }}
                          </a>
                        </span>
                      </checkbox>
                      <div v-if="errors.length" class="error">
                        {{$t('Please agree with rules')}}
                      </div>
                    </div>
                  </ValidationProvider>
                </label>
              </div>
              <transition name="slide">
                <div v-if="error" class="general-error">{{ error }}</div>
              </transition>
              <div class="buttons">
                <div class="amount">
                  <div class="desc">{{ $t('YOU WILL GET') }}</div>
                  <div class="desc">
                    <b>{{ pointsAmount }} {{ $t('POINTS') }}</b>
                  </div>
                </div>
                <button type="submit" class="button red">{{ $t('PAY') }}</button>
              </div>
              <div class="payment-methods">
                <img src="../assets/methods/visa.png" alt="">
                <img src="../assets/methods/mc.png" alt="">
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import axios from "@/axios";
import {mapGetters} from "vuex";
import Checkbox from "@/components/Checkbox.vue";

export default {
  name: "TopUpModal",
  props: [],
  components: {
    ValidationProvider,
    ValidationObserver,
    Checkbox
  },
  created() {
    if (this.bundleId) {
      this.getCountries();
      this.bundle = this.bundles(this.bundleId)
      this.amount = this.bundle.price
      this.step = 2;
    }
  },
  mounted() {
    this.email = this.profile.email;
    this.name = this.profile.name;
    this.surname = this.profile.surname;
    this.phone = this.profile.phone;
  },
  data: function () {
    return {
      amount: 5,
      email: '',
      name: '',
      surname: '',
      phone: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      step: 1,
      countryOptions: [],
      error: '',
      bundle: {},
      agree: false
    };
  },
  methods: {
    passFirstStep() {
      if (!this.countryOptions.length) {
        this.getCountries();
      }

      this.step = 2;
    },
    getCountries() {
      axios.get(process.env.VUE_APP_API + 'countries')
          .then((res) => {
            this.countryOptions = res.data.payload;
          })
    },
    checkout() {
      const data = {
        name: this.name,
        surname: this.surname,
        phone: this.phone,
        country: this.country,
        city: this.city,
        address: this.address,
        postCode: this.postCode,
      };

      if (this.bundleId) {
        data['bundle_id'] = this.bundleId;
      } else {
        data['amount'] = this.amount
      }

      axios.post(process.env.VUE_APP_API + 'deposit/checkout', data).then((res) => {
        if (res.data.status == "OK") {
          window.location = res.data.redirect_url;
        } else {
          this.error = res.response.data.message
        }
      })
      // .catch((res) => {
      //   this.error = res.response.data.message
      //   if (res.response.status === 401) {
      //     this.openSignInModal();
      //   }
      // })
    }
  },
  computed: {
    ...mapGetters('user', ['profile']),
    ...mapGetters('app', ['bundleId', 'bundles', 'settings']),
    pointsAmount() {
      return this.bundle.points ? this.bundle.points : this.amount * this.settings('points_convertion_rate')
    }
  }
};
</script>

<style lang="scss">
.modal .wrapper {
  overflow-y: hidden;

  .container {
    overflow-y: auto;
  }
}

</style>
