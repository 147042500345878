<template>
  <main class="main inside-page payment-result-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="page-inside-top">
          <div class="wrapper">
            <div class="title big">
              {{$t('Thank you for your payment. Your transaction has been completed successfully.')}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: 'PaymentSuccessPage',
  components: {
  },
  props: [],
  data: function() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>